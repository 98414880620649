import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DalService } from '../../providers/dal.service';

@Component({
  selector: 'signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  signinform: FormGroup;
  Email: string;
  error_msg:string = '';
  form_submitted:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dal: DalService,
    private router: Router
  ) { }

  ngOnInit() {
    this.signinform = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.pattern(this.dal.email_pattern)]],
    });
    localStorage.clear();
    this.signinform.get("Email").setValue(this.dal.loginid);
  }

  get form_error() {  return this.signinform.controls; }

  onSubmit() {
    this.form_submitted = true;
    if (this.signinform.invalid) {
      return;
    } else{
      const now = Math.round(Date.now() / 1000);// Unix timestamp in milliseconds
      const LOGINID = this.signinform.value.Email;
      const KEY = now;
      this.dal.setLoginid(LOGINID);

      this.dal.getUrl(LOGINID, KEY).subscribe((res: any) => {
        if (res.RESPONSE == "SUCCESS") {
          this.dal.setOrgData(res.DATA);
          this.router.navigate(['/login']); // , { replaceUrl: true }
        } else if(res.RESPONSE == "ERROR"){
          this.error_msg = res.MESSAGE;
        }
      },
        (err: any) => {
          console.log(err);
        });
      }
  }

}
