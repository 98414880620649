 <nb-layout>
  <nb-layout-column>
      <nb-card>
          <nb-card-body>

            <!-- <section> -->
              <div class="login_wrapper">
                  <h1 id="title" class="title">Welcome To {{org_name}}</h1>
                  <p class="sub-title">Hello! Log in with your email.</p>

                  <nb-alert *ngIf="signInErrorMessage.length > 0" outline="danger" status="alert">
                      <ul class="alert-message-list">
                          <li class="alert-message">{{ signInErrorMessage }}</li>
                      </ul>
                  </nb-alert>

                  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                      <!-- <div class="form-control-group">
                        <label class="label" for="userEmail">Email :</label>
                      <input type="email" nbInput id="userEmail" fullWidth readonly fieldSize="large" status="primary" name='userEmail' placeholder="Enter username" formControlName="userEmail">
                    </div> -->
                    
                      <div class="form-group">
                        <span >Registered Email Id :</span>
                        <span> {{userEmail}}</span>
                      </div>

                      <div class="form-group">
                        <span >Registered Mobile No :</span>
                        <span> +91 ******1234</span>
                      </div>
                    
                      <div class="form-group">
                        <label class="label" for="userPassword">Password :</label>
                        <input type="password" id="userPassword" #focus_input nbInput fullWidth fieldSize="large" 
                        [status]="f.userPassword.dirty ? (f.userPassword.invalid  ? 'danger' : 'success') : 'basic'" placeholder="Password" 
                        [attr.aria-invalid]="f.userPassword.invalid && f.userPassword.touched ? true : null"
                        formControlName="userPassword">
                        <div class='mt-1' *ngIf="f.userPassword.errors">
                          <div *ngIf="f.userPassword.dirty && f.userPassword.errors.required" class="text-danger">
                            Password is required
                          </div>
                        </div>
                      

                      <div class="form-group">
                        <!-- [nbSpinner]="loading" nbSpinnerStatus="info" -->
                        <button type="submit" nbButton class="float-right" shape="rectangle" status="primary">
                          Log In
                        </button>
                      </div>
                    </div>

                  </form>

              </div>
            <!-- </section> -->

          <!-- <section>
              <div class="row">
                <div class="col-12 col-md-8 mx-auto" style="padding-bottom: 10px;" *ngIf="org_data['mobile_otp']=='Yes'">
                  <button type="button" nbButton outline fullWidth shape="rectangle" status="info">
                    Request OTP by SMS
                  </button>
                </div>
                <br>
                <div class="col-12 col-md-8 mx-auto" style="padding-bottom: 10px;" *ngIf="org_data['email_otp']=='No'">
                  <button type="button" nbButton outline fullWidth shape="rectangle" status="info">
                    Request OTP by Email
                  </button>
                </div>
                
              </div>
            </section> -->

          </nb-card-body>
      </nb-card>
  </nb-layout-column>
</nb-layout>