<div class="header-container">
  <div class="logo-container">
    <!-- <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a> -->

    <a (click)="goBack()" *ngIf='isSmallScreen && presentUrl!="/pages" && presentUrl!="/pages/dashboard"' href="#" class="sidebar-toggle" >
      <nb-icon icon="arrow-back-outline" status="primary"></nb-icon>
    </a>

    <a class="sidebar-toggle" href="#" (click)="navigateHome()">
      <span>
      <img src = "../../../../assets/images/denisys_logo.jpg" alt = "Logo" />
    </span>
  </a>
  </div>

</div>

<div *ngIf='!isSmallScreen' style='margin-top: 20px;'>
  <!-- <h4>Student App</h4> -->
</div>

<div class="header-container">
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action> 
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
  
  [picture]="user?.picture"  [onlyPicture]="userPictureOnly" -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <b><nb-user [nbContextMenu]="userMenu" [name]="user">
      </nb-user></b>
    </nb-action>
  </nb-actions>
</div>
