import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient} from '@angular/common/http';
import { Observable ,  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DalService {
  public token: string;
  public baseurl: string;
  public orgid: string;
  public loginid: string;
  public email_pattern: any;
  public valid_str_pattern: any;

  constructor(
    private http: HttpClient,
  ) {
    this.email_pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    this.valid_str_pattern = /[^a-zA-Z0-9-@$*!#%&,()^\[\]\{\}<>?|\\='"`~:;/+_.\s]/;;
  }

  setToken(token: string): void{
    this.token = token;
    localStorage.setItem('TOKEN', token);
  }

  setOrgData(data): void{
    this.baseurl = data.api_base_url + environment.api_version + '/';
    this.orgid = data.org_id;
    localStorage.setItem('BASEURL', this.baseurl);
    localStorage.setItem('ORG_DATA', JSON.stringify(data));
  }

  setLoginid(loginid: string): void{
    this.loginid = loginid;
    localStorage.setItem('UNAME',loginid);
  }

  private formatErrors(error: any): Observable<any>{
    return  throwError(error.error);
  }

  signIn(endpoint: string, USERID: string, PASSWORD: string): Observable<any> {
    const ORGID = this.orgid;
    let url = this.baseurl+endpoint;
     //   return this.http.post(`${APIBASEURL}${endpoint}`, {USERID, PASSWORD, ORGID})
    return this.http.post(url, JSON.stringify({USERID, PASSWORD, ORGID}))
      .pipe(
        catchError(this.formatErrors)
      );
  }

  getUrl(LOGINID, KEY): Observable<any> {
  return this.http.post(`${environment.api_url}`, { LOGINID, KEY})
    .pipe(catchError(this.formatErrors));
  }

  // post(endpoint: string, params: any): Observable<any> {
  //   params.TOKEN = this.token;
  //   params.end_point = endpoint;
  //   return this.http.post(`${environment.api_url}`, { params })
  //     .pipe(catchError(this.formatErrors));
  // }

  getData(endpoint: string): Observable<any> {
    const TOKEN = this.token;
    const APIBASEURL = this.baseurl;
    return this.http.post(`${APIBASEURL}${endpoint}`, { TOKEN })
      .pipe(catchError(this.formatErrors));
  }

  uploadDocNew(endpoint: string, filedata): Observable<any> {
    const APIBASEURL = this.baseurl;
    const TOKEN = this.token;
    //  return this.http.post<any>(`${environment.upload_api}${endpoint}`, filedata)
    return this.http.post<any>(`${APIBASEURL}${endpoint}`, filedata)
      .pipe(catchError(this.formatErrors));
  }

  uploadDoc(endpoint: string, formdata): Observable<any> {
    console.log(formdata);
    const TOKEN = this.token;
    const APIBASEURL = this.baseurl;
    return this.http.post<any>(`${APIBASEURL}${endpoint}`, { TOKEN , formdata})
    .pipe(catchError(this.formatErrors));
  }

   get(endpoint: string, params: any): Observable<any> {
    params.TOKEN = this.token;
    return this.http.get(`${environment.api_url}${endpoint}`, { params })
       .pipe(catchError(this.formatErrors));
   }

  put(endpoint: string, params: any): Observable<any> {
    params.TOKEN = this.token;
    return this.http.put(`${environment.api_url}${endpoint}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  delete(endpoint: string, params: any): Observable<any> {
    params.TOKEN = this.token;
    return this.http.delete(`${environment.api_url}${endpoint}`, { params })
      .pipe(catchError(this.formatErrors));
  }

}
