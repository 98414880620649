import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common'; //For the purpose of adding back navigation
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any ;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [
     { title: 'Profile', icon: 'person-outline'},
     { title: 'Logout',icon: 'unlock-outline' } 
    ];

  public isSmallScreen:boolean = false;
  presentUrl: string;
  profileData: any;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private breakpointService: NbMediaBreakpointsService,
              private router :Router,
              private location: Location,
              public breakpointObserver:BreakpointObserver
  ) {
    /* This is for listening to click events in the userMenu */
    this.menuService.onItemClick()
    .subscribe((event) => {
      if(event.item.title == 'Profile'){
        this.router.navigateByUrl('pages/profile');
      }
      else if(event.item.title == 'Logout'){
        this.router.navigate(['/'], { replaceUrl: true })
      }
    });
    /*********************************************************/

    //Subscribing to Navigation
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.presentUrl = event['url'];
    });
  }

  ngOnInit() {
        //Retriving the Profile Details
        this.profileData = JSON.parse(localStorage.getItem('profile'));
        this.user = { name: this.profileData.name }
        // this.user = 'Student Name';
        // console.log('Get student name from login');
        //Listening to Screen resize events
    
      this.breakpointObserver.observe([
        '(max-width: 768px)'
          ]).subscribe(result => {
            if (result.matches) {
              this.isSmallScreen = true;
            } else {
              this.isSmallScreen = false;
            }
          });
  
    /*********************************************************/

    this.currentTheme = this.themeService.currentTheme;
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }


  navigateHome() {
    this.router.navigateByUrl('/pages');
    return false;
  }

  goBack(){
    this.location.back();
    return false;
  }
}
