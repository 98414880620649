import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DalService } from '../../providers/dal.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    userEmail: string = '';
    org_name: string = 'Student Portal';
    org_data = {};
    signInErrorMessage:string = '';

    @ViewChild("focus_input") focusInput: ElementRef;
    ngAfterViewInit() {
      this.focusInput.nativeElement.focus();
    }

  constructor(
    private formBuilder: FormBuilder,
    private router :Router,
    private dal : DalService,
  ) {}
  
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      // userEmail: ['', Validators.required ],
      userPassword: ['', Validators.required]
    });
    // this.loginForm.get("userEmail").setValue(this.dal.loginid);
    this.userEmail = this.dal.loginid;
    this.org_data = JSON.parse(localStorage.getItem('ORG_DATA'));
    if(this.org_data['title'] != ''){
      this.org_name = this.org_data['title'];
    }
  }

  // convenience getter for easy access to form fields
  get f() { 
    return this.loginForm.controls;
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    } else{
      this.loading = true;

      const USERID = this.userEmail;  //this.loginForm.value.userEmail;
      const PASSWORD = this.loginForm.value.userPassword;
      const ORGID = this.org_data['org_id'];
      
      this.dal.signIn("sign_in",USERID,PASSWORD).subscribe((res: any) => { 
        if(res.RETURN == "TRUE"){
           this.dal.setToken(res.TOKEN);
           localStorage.setItem('profile', JSON.stringify(res.DETAIL));
           /* sign success navigate to pages */
           this.router.navigate(['/pages'], { replaceUrl: true });
        }else{
          this.loading = false;
          this.signInErrorMessage = res.ERROR;
        }
      }, (err: any) => {
        this.loading = false;
        console.log(err);
      });

    }
    
  
  }
}