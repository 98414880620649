import { Component, OnInit } from '@angular/core';
import { DalService } from 'src/providers/dal.service';
import { AnalyticsService } from './@core/utils/analytics.service';

/* Hard Coded Json Data */
// import * as data from './pages/jsonFiles/userData.json';


@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  // profileData: any = (data as any).default;

  constructor(
    private analytics: AnalyticsService,  private dal : DalService,
    ) {
  }

  ngOnInit() {
    // This is for Tracking page Views Of the user
    this.analytics.trackPageViews();

    let user_name = localStorage.getItem('UNAME');
    if(user_name != '' && user_name != null){
      this.dal.loginid = user_name;
    }
    let base = localStorage.getItem('BASEURL');
    if(base != '' && base != null){
      this.dal.baseurl = base;
    }
    let token = localStorage.getItem('TOKEN');
    if(token != '' && token != null){
      this.dal.token = token;
    }
    // Setting data in the local storage
    // localStorage.setItem('data',JSON.stringify(this.profileData));
  }



}
