import { Component } from '@angular/core';
import { Location } from '@angular/common'; //For the purpose of adding back navigation
import { Router, NavigationEnd } from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <span class="created-by">
  Powered by 
  <img src="../../../../assets/images/maple.png" height="15" width="15">
   <a href="https://maplecrm.com/" target="_blank"> &nbsp;
   Maple CRM</a> &trade;
  </span>
    `,
})
export class FooterComponent {
  public isSmallScreen:boolean = false;
  public presentUrl:string;

  constructor(
    public location: Location,
    private router :Router,
    )
  {
    //Subscribing to Navigation
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
            this.presentUrl = event['url'];
        });
  }


  ngOnInit() {
 }
}


