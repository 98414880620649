<!-- <nb-layout>
    <nb-layout-column>
        <div class="row">
            <div class="col-lg-4 col-md-3 col-xs-12"></div>
                <nb-card >
                    <nb-card-body>
                      <nb-form-field [formGroup]="signinform">
                        <label for="Email"><h6>Email Id</h6></label><br> 
                        <input type="email" status="primary" formControlName="Email" status="primary" nbInput>
                        <button nbSuffix nbButton status="primary" shape="rectangle" (click)="onSubmit()">
                            <nb-icon icon="arrow-right"> </nb-icon>
                          </button>
                        <div class='mt-1' *ngIf="form_error.Email.errors && form_submitted">
                            <div *ngIf="form_error.Email.touched && form_error.Email.errors.required" class="text-danger">Email is required</div>
                            <div *ngIf="form_error.Email.dirty && form_error.Email.errors.pattern" class="text-danger">Please enter a valid email address</div>
                        </div>
                        <div *ngIf="error_msg.length > 0" class="text-danger">{{error_msg}}</div>
                      </nb-form-field>
                      <p></p>
                    </nb-card-body>
                  </nb-card>
            </div>
    </nb-layout-column>
</nb-layout> 
showMessages.error && errors?.length && !submitted
[(ngModel)]="user.rememberMe" *ngIf="rememberMe"
-->
<nb-layout>
    <nb-layout-column>
        <nb-card>
            <nb-card-body>

                <div class="login_wrapper">
                    <h1 id="title" class="title">Welcome To Student Portal</h1>
                    <p class="sub-title">Hello! Log in with your email.</p>

                    <nb-alert *ngIf="error_msg.length > 0" outline="danger" status="alert">
                        <ul class="alert-message-list">
                            <li class="alert-message">{{ error_msg }}</li>
                        </ul>
                    </nb-alert>

                    <form [formGroup]="signinform" (ngSubmit)="onSubmit()">

                        <div class="form-control-group">
                            <label class="label" for="input-email">Email :</label>
                            <input nbInput fullWidth formControlName="Email" pattern=".+@.+\..+" id="input-email"
                                placeholder="Email address" fieldSize="large" 
                                [status]="form_error.Email.dirty ? (form_error.Email.invalid  ? 'danger' : 'success') : 'basic'"
                                required
                                [attr.aria-invalid]="form_error.Email.invalid && form_error.Email.touched ? true : null">
                            <ng-container *ngIf="form_error.Email.invalid && form_submitted">
                                <p class="caption status-danger" *ngIf="form_error.Email.errors?.required">
                                    Email is required!
                                </p>
                                <p class="caption status-danger" *ngIf="form_error.Email.errors?.pattern">
                                    Please enter a valid email address
                                </p>
                            </ng-container>
                        </div>

                        <!-- <button nbButton fullWidth status="primary" size="large" [class.btn-pulse]="form_submitted"
                            (click)="onSubmit()"> 
                            Next
                        </button> 
                      [nbSpinner]="loading" nbSpinnerStatus="info" (click)="onSubmit()" -->
                        <div class="form-group">
                            <button type="submit" nbButton class="float-right" shape="rectangle" status="primary" >
                                Next
                            </button>
                          </div>
                    </form>
                </div>

            </nb-card-body>
        </nb-card>
    </nb-layout-column>
</nb-layout>


<!-- <section class="another-action" aria-label="Register">
  Don't have an account? <a class="text-link" routerLink="../register">Register</a>
</section> -->
